// Third-party libraries
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

// Local dependencies
import { LoadingBar } from "../components/widgets";
import { getHeaders, errorHandler, transactionsUrl } from "../apihelpers";
import { Meta, NotLoggedIn } from "../utilities";
import TransactionItem from "../components/TransactionItem";

class Transactions extends Component {
  initialTransactions = 20;

  state = {
    loading: true,
    unauthorized: false,
    moreTransactionsAvailable: false,
  };

  _isMounted = false;

  getTransactions(count = false) {
    window.scrollTo(0, 0);
    const url = count
      ? transactionsUrl + "/" + parseInt(count)
      : transactionsUrl;
    const headers = getHeaders();
    fetch(url + "?" + Date.now(), {
      method: "GET",
      headers,
    })
      .then(errorHandler)
      .then((data) => {
        if (this._isMounted) {
          this.setState({
            transactions: data,
            loading: false,
            unauthorized: false,
            moreTransactionsAvailable: data.length === this.initialTransactions,
          });
          localStorage.setItem("transactionHistory", JSON.stringify(data));
        }
      })
      .catch((error) => {
        console.log(error);
        if (this._isMounted) {
          this.setState({ unauthorized: true });
        }
        this.props.logout();
      });
  }

  componentDidMount() {
    this._isMounted = true;
    if (localStorage.transactionHistory) {
      this.setState(
        {
          transactions: JSON.parse(localStorage.getItem("transactionHistory")),
        },
        () => this.getTransactions(this.initialTransactions)
      );
    } else {
      this.getTransactions(this.initialTransactions);
    }
    if (this.props.player && this.props.loggedIn) {
      this.props.getPlayer();
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (!this.props.loggedIn || this.state.unauthorized) {
      return (
        <Fragment>
          <Meta
            title="Transactions (not logged in)"
            description="Your Rouleur Derby transactions."
          />
          <NotLoggedIn />
        </Fragment>
      );
    }

    if (!this.state.transactions) {
      return (
        <Fragment>
          <Meta
            title="Transactions"
            description="Your Rouleur Derby transactions."
          />
          <Headline player="{this.props.player}" />
          <LoadingBar />
        </Fragment>
      );
    }

    if (this.state.transactions) {
      return (
        <Fragment>
          <Meta
            title="Transactions"
            description="Your Rouleur Derby transactions."
            pageview={false}
          />
          <Headline player={this.props.player} />
          {this.state.loading && <LoadingBar />}
          {this.state.transactions.length > 0 ? (
            <Table transactions={this.state.transactions} />
          ) : (
            <p>You have no transactions recorded.</p>
          )}
          {this.state.moreTransactionsAvailable && (
            <button
              type="button"
              onClick={() => {
                this.setState({ loading: true }, () => this.getTransactions());
              }}
            >
              Get more
            </button>
          )}
        </Fragment>
      );
    } else {
      return null;
    }
  }
}

function Headline(props) {
  return <h1>Transaction History for {props.player.screen_name}</h1>;
}

function Table(props) {
  return (
    <div className="sticky-table-outer">
      <table className="transaction-table table--sticky">
        <thead>
          <tr>
            <th>Activity</th>
            <th>Date</th>

            <th className="figure">Points</th>

            <th className="figure">New balance</th>
          </tr>
        </thead>
        <tbody>
          {props.transactions.map((transaction, i) => {
            return <TransactionItem key={i} transaction={transaction} />;
          })}
        </tbody>
      </table>
    </div>
  );
}

Transactions.propTypes = {
  transactions: PropTypes.object,
  loading: PropTypes.bool,
  unauthorized: PropTypes.bool,
};

export default Transactions;
