// Third-party libraries
import React, { Fragment } from "react";

// Project dependencies
import { Proposition } from "../../shared/interfaces";
import ResolutionSet from "./ResolutionSet";

function Resolutions({ bets }) {
  if (!bets || bets.length === 0) {
    return null;
  }

  let props: Array<Proposition> = bets
    .map(bet => bet.proposition)
    .filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj["slug"]).indexOf(obj["slug"]) === pos;
    });

  return (
    <Fragment>
      <h2 className="proposition-menu__h2">
        <span>Recent scores</span>
      </h2>

      <div className="proposition-menu">
        {props.map(prop => (
          <ResolutionSet
            proposition={prop}
            bets={bets.filter(bet => bet.proposition.slug === prop.slug)}
          />
        ))}
      </div>
    </Fragment>
  );
}

export default Resolutions;
