// Third-party libraries
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

// Project dependencies
import {
  getHeaders,
  errorHandler,
  playerUrl,
  adjustmentUrl
} from "../apihelpers";
import { Meta, NotStaff } from "../utilities";
import { LoadingBar } from "../components/widgets";
import SuccessIcon from "../svg/SuccessIcon";

class Adjustment extends Component {
  state = {
    loading: false,
    success: false,
    players: [],
    player_to_adjust: 0, //expecting ID
    amount: "",
    memo: ""
  };

  _isMounted = false;

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getPlayers() {
    const headers = getHeaders();
    fetch(playerUrl, {
      method: "GET",
      headers
    })
      .then(errorHandler)
      .then(data => {
        if (this._isMounted) {
          this.setState({ players: data });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidMount() {
    this._isMounted = true;
    this.getPlayers();
  }

  componentWillUnomunt() {
    this._isMounted = false;
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    // Winners get stored in state. If it's a show proposition, up to three option_data IDs will be stored there.
    // Otherwise, just one. This is what gets passed to the back end. If the correct number of winners has
    // been selected, the submit button will show up.
    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    const headers = getHeaders();
    this.setState({ loading: true, success: false });
    fetch(adjustmentUrl, {
      method: "POST",
      headers,
      body: JSON.stringify({
        player: this.state.player_to_adjust,
        amount: this.state.amount,
        memo: this.state.memo
      })
    })
      .then(errorHandler)
      .then(response => {
        if (response.error === false) {
          this.setState({
            loading: false,
            success: true,
            amount: "",
            player_to_adjust: 0,
            memo: ""
          });
        }
      });

    event.preventDefault();
  }

  render() {
    if (this.props.player && !this.props.player.is_staff) {
      return (
        <Fragment>
          <Meta
            title="Non-staff visit to adjustment interface"
            description="Adjustments are made."
          />
          <NotStaff />
        </Fragment>
      );
    } else if (this.state.players.length > 0) {
      return (
        <Fragment>
          <Meta
            title="Adjustment"
            description="Correcting an error or other one-off adjustment"
          />
          <h1>Adjustment</h1>
          {this.state.success && (
            <ul className="bet-messages">
              <li className={`bet-message bet-message--success`}>
                <span className="bet-message__icon">
                  <SuccessIcon />
                </span>
                <span className="bet-message__text">Adjustment made.</span>
              </li>
            </ul>
          )}
          <form onSubmit={this.handleSubmit}>
            <label htmlFor="player">Player</label>
            <select
              id="player_to_adjust"
              name="player_to_adjust"
              onChange={this.handleChange}
              defaultValue={this.state.player_to_adjust}
            >
              <option value="0">---</option>
              {this.state.players
                .sort((a, b) => {
                  var nameA = a.screen_name.toLowerCase(),
                    nameB = b.screen_name.toLowerCase();
                  if (nameA < nameB)
                    //sort string ascending
                    return -1;
                  if (nameA > nameB) return 1;
                  return 0; //default return value (no sorting)
                })
                .map((player, i) => {
                  return (
                    <option key={i} value={player.id}>
                      {player.screen_name}
                    </option>
                  );
                })}
            </select>
            <label htmlFor="amount">Amount</label>
            <input
              type="text"
              size="5"
              pattern="[\-0-9,]*"
              value={this.state.amount}
              name="amount"
              id="amount"
              placeholder="Use negative numbers to deduct points"
              onChange={this.handleChange}
            />

            <label htmlFor="amount">Memo</label>
            <input
              type="text"
              size="60"
              name="memo"
              id="memo"
              value={this.state.memo}
              onChange={this.handleChange}
            />
            {!this.state.loading && (
              <button className="button--bet">Send adjustment</button>
            )}
          </form>
        </Fragment>
      );
    } else {
      return <LoadingBar />;
    }
  }
}

Adjustment.propTypes = {
  loading: PropTypes.bool,
  success: PropTypes.bool,
  player_to_adjust: PropTypes.number,
  amount: PropTypes.number,
  memo: PropTypes.string,
  players: PropTypes.array
};

export default Adjustment;
