// Third-party libraries
import React, { Component, Fragment, Suspense, lazy } from "react";
import PropTypes from "prop-types";
import { Route, Switch, withRouter } from "react-router-dom";
// Local dependencies
import { googleAnalyticsID, Meta } from "./utilities";
import { LoadingBar } from "./components/widgets";
import Adjustment from "./pages/Adjustment";
import Archive from "./pages/Archive";
import Banner from "./components/Banner";
import Bets from "./pages/Bets";
import Bonus from "./pages/Bonus";
import BetHistory from "./components/BetHistory";
import Changes2019 from "./pages/Changes2019";
import Contest from "./pages/Contest";
import DigestUnsubscribe from "./pages/DigestUnsubscribe";
import FileNotFound from "./pages/FileNotFound";
import Footer from "./components/Footer";
import HomeWelcome from "./components/HomeWelcome";
import LeagueOptout from "./pages/LeagueOptout";
import LoanOffer from "./components/LoanOffer";
import LoginForm from "./components/LoginForm";
import PropositionMenu from "./pages/PropositionMenu";
import ReminderUnsubscribe from "./pages/ReminderUnsubscribe";
import ResetPassword from "./pages/ResetPassword";
import ResetPasswordConfirm from "./pages/ResetPasswordConfirm";
import Resolutions from "./components/resolutions/Resolutions";
import Score from "./pages/Score";
import Scratch from "./pages/Scratch";
import SendDigest from "./pages/rd/SendDigest";
import Sidebar from "./components/Sidebar";
import Transactions from "./pages/Transactions";
import "./App.scss";

import {
  selfUrl,
  getCookie,
  setCookie,
  getHeaders,
  errorHandler,
} from "./apihelpers.js";

const About = lazy(() => import("./pages/About"));
const NewPlayer = lazy(() => import("./pages/NewPlayer"));
const Returns = lazy(() => import("./pages/Returns"));
const PropositionPage = lazy(() => import("./pages/Proposition"));
const Rules = lazy(() => import("./pages/Rules"));
const Terms = lazy(() => import("./pages/Terms"));
const Privacy = lazy(() => import("./pages/Privacy"));
const Leaders = lazy(() => import("./pages/Leaders"));
const League = lazy(() => import("./pages/League"));
const Leagues = lazy(() => import("./pages/Leagues"));
const Player = lazy(() => import("./pages/Player"));
const ScoreHistory = lazy(() => import("./pages/ScoreHistory"));

// Set a cookie with the invitation code, if present. Visitors will
// get a bonus if they proceed to create an account.
const urlParams = new URLSearchParams(window.location.search);
if (urlParams.has("invite")) {
  setCookie("invite", urlParams.get("invite"), 30);
}

class App extends Component {
  state = {
    loggedIn: false,
    player: {},
    betMessages: [],
    scrolled: false,
    showSidebarLogin: false,
    tookLoanOnThisPage: false,
    repaidLoanOnThisPage: false,
    showMobileSidebar: false,
  };

  constructor(props) {
    super(props);
    this.afterLoan = this.afterLoan.bind(this);
    this.afterLoanPayback = this.afterLoanPayback.bind(this);
    this.afterLogin = this.afterLogin.bind(this);
    this.getPlayer = this.getPlayer.bind(this);
    this.hideMobileSidebar = this.hideMobileSidebar.bind(this);
    this.logout = this.logout.bind(this);
    this.toggleShowMobileSidebar = this.toggleShowMobileSidebar.bind(this);
    this.toggleSidebarLogin = this.toggleSidebarLogin.bind(this);
  }

  afterLogin() {
    this.setState({ showMobileSidebar: false });
    this.getPlayer();
  }

  afterLoan() {
    this.getPlayer();
    this.setState({ tookLoanOnThisPage: true });
  }

  afterLoanPayback() {
    this.getPlayer();
    this.setState({ repaidLoanOnThisPage: true });
  }

  toggleShowMobileSidebar() {
    const showMobileSidebar = () => this.state.showMobileSidebar;
    this.setState({
      showMobileSidebar: showMobileSidebar() === true ? false : true,
    });
  }

  hideMobileSidebar() {
    this.setState({
      showMobileSidebar: false,
    });
  }

  toggleSidebarLogin() {
    this.setState({
      showSidebarLogin: true,
    });
  }

  logout() {
    localStorage.clear();
    setCookie("token", "");
    this.setState({ player: {}, loggedIn: false, showMobileSidebar: false });
  }

  getPlayer() {
    if (getCookie("token").length > 0) {
      return new Promise((resolve, reject) => {
        const headers = getHeaders();
        if (getCookie("token").length < 1) {
          reject("Player does not appear to be logged in.");
        }
        fetch(selfUrl + "?" + Date.now(), {
          method: "GET",
          headers,
        })
          .then(errorHandler)
          .then((data) => {
            window.gtag("config", googleAnalyticsID, {
              user_id: data.id,
              send_page_view: false,
            });
            localStorage.setItem("player", JSON.stringify(data));
            this.setState(
              {
                player: data,
                loggedIn: true,
              },
              () => resolve(true)
            );
          })
          .catch((error) => {
            setCookie("token", "");
            this.setState({
              player: {},
              loggedIn: false,
            });
            localStorage.clear();
            console.log(error);
          });
      });
    }
  }

  componentDidMount() {
    window.landingPath = window.location.pathname;

    // Look in local storage for player stats. If present, load it, then get updated stats.
    if (localStorage.getItem("player") && getCookie("token").length > 0) {
      this.setState(
        { loggedIn: true, player: JSON.parse(localStorage.getItem("player")) },
        () => this.getPlayer()
      );
    }
    // If not in local storage, go directly to get stats.
    else {
      this.getPlayer();
    }

    window.addEventListener("scroll", (event) => {
      if (window.scrollY < 20 && this.state.scrolled) {
        this.setState({ scrolled: false });
      }
      if (window.scrollY >= 20 && !this.state.scrolled) {
        this.setState({ scrolled: true });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // Things to do if the location has changed
      // 1. Scroll to top
      window.scrollTo(0, 0);

      // 2/ Reset a few things in the state
      this.setState({
        showMobileSidebar: false,
        tookLoanOnThisPage: false,
        repaidLoanOnThisPage: false,
      });
    }
  }

  render() {
    return (
      <Fragment>
        <Banner
          scrolled={this.state.scrolled}
          showMobileSidebar={this.state.showMobileSidebar}
          toggleShowMobileSidebar={this.toggleShowMobileSidebar}
        />

        <Sidebar
          player={this.state.player}
          loggedIn={this.state.loggedIn}
          afterLogin={this.afterLogin}
          logout={this.logout}
          showSidebarLogin={this.state.showSidebarLogin}
          toggleSidebarLogin={this.toggleSidebarLogin}
          showMobileSidebar={this.state.showMobileSidebar}
          path={this.props.location.pathname}
        />

        {/* Show the welcome message on the home page and prop pages. */}

        <Route
          path="/admin"
          exact
          component={() => {
            window.location = "https://api.rouleurderby.com/admin";
            return null;
          }}
        />

        <Route
          exact
          path={["/", "/prop/:slug", "/" + this.state.player.handle]}
          render={(props) => (
            <HomeWelcome
              {...props}
              hideMobileSidebar={this.hideMobileSidebar}
              showMobileSidebar={this.state.showMobileSidebar}
              loggedIn={getCookie("token").length > 0}
            />
          )}
        />

        {this.state.loggedIn && (
          <Route
            exact
            path={["/", "/prop/:slug", "/" + this.state.player.handle]}
            render={(props) => (
              <LoanOffer
                {...props}
                afterLoan={this.afterLoan}
                afterLoanPayback={this.afterLoanPayback}
                tookLoanOnThisPage={this.state.tookLoanOnThisPage}
                repaidLoanOnThisPage={this.state.repaidLoanOnThisPage}
                player={this.state.player}
              />
            )}
          />
        )}

        <main
          className={
            this.state.showMobileSidebar ? "main--show-mobile-sidebar" : ""
          }
        >
          <section>
            {this.state.loggedIn && (
              <Route
                exact
                path="/"
                render={(props) => (
                  <Resolutions
                    {...props}
                    bets={this.state.player.resolutions}
                  />
                )}
              />
            )}
            <Suspense fallback={<LoadingBar />}>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(props) => (
                    <PropositionMenu
                      {...props}
                      loggedIn={this.state.loggedIn}
                      player={this.state.player}
                    />
                  )}
                />
                <Route
                  exact
                  path="/rd/2019changes"
                  render={(props) => <Changes2019 {...props} />}
                />
                <Route
                  exact
                  path="/rd/about"
                  render={(props) => <About {...props} />}
                />
                <Route
                  exact
                  path="/rd/rules"
                  render={(props) => <Rules {...props} />}
                />
                <Route
                  exact
                  path="/returns"
                  render={(props) => <Returns {...props} />}
                />
                <Route
                  exact
                  path="/rd/terms"
                  render={(props) => <Terms {...props} />}
                />
                <Route
                  exact
                  path="/rd/privacy"
                  render={(props) => <Privacy {...props} />}
                />
                <Route
                  exact
                  path="/contest/:slug"
                  render={(props) => (
                    <Contest
                      {...props}
                      loggedIn={this.state.loggedIn}
                      player={this.state.player}
                    />
                  )}
                />
                <Route
                  exact
                  path="/prop/:slug"
                  render={(props) => (
                    <PropositionPage
                      {...props}
                      loggedIn={this.state.loggedIn}
                      player={this.state.player}
                      betMessages={this.state.betMessages}
                      getPlayer={this.getPlayer}
                      logout={this.logout}
                    />
                  )}
                />
                <Route
                  exact
                  path="/scratch/:slug"
                  render={(props) => (
                    <Scratch {...props} player={this.state.player} />
                  )}
                />
                <Route
                  exact
                  path="/score/:slug"
                  render={(props) => (
                    <Score {...props} player={this.state.player} />
                  )}
                />
                <Route
                  exact
                  path="/rd/adjustment"
                  render={(props) => (
                    <Adjustment {...props} player={this.state.player} />
                  )}
                />
                <Route
                  exact
                  path="/bets"
                  render={(props) => {
                    return (
                      <Bets
                        {...props}
                        player={this.state.player}
                        loggedIn={this.state.loggedIn}
                        logout={this.logout}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/bonus/:code"
                  render={(props) => {
                    return (
                      <Bonus
                        {...props}
                        player={this.state.player}
                        loggedIn={this.state.loggedIn}
                        logout={this.logout}
                        afterLogin={this.afterLogin}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path="/transactions"
                  render={(props) => (
                    <Transactions
                      {...props}
                      player={this.state.player}
                      loggedIn={this.state.loggedIn}
                      logout={this.logout}
                      getPlayer={this.getPlayer}
                    />
                  )}
                />
                <Route
                  exact
                  path="/rd/leaders"
                  render={(props) => (
                    <Leaders {...props} player={this.state.player} />
                  )}
                />
                <Route
                  exact
                  path="/rd/archive"
                  render={(props) => (
                    <Archive
                      {...props}
                      loggedIn={this.state.loggedIn}
                      player={this.state.player}
                    />
                  )}
                />
                <Route
                  exact
                  path="/rd/login"
                  render={() => (
                    <Fragment>
                      <Meta
                        title="Log In"
                        description="Logging in to Rouleur Derby."
                      />
                      <LoginForm
                        afterLogin={this.afterLogin}
                        player={this.state.player}
                        loggedIn={this.state.loggedIn}
                      />
                    </Fragment>
                  )}
                />
                <Route
                  exact
                  path="/rd/new"
                  render={(props) => (
                    <NewPlayer
                      {...props}
                      player={this.state.player}
                      loggedIn={this.state.loggedIn}
                      afterLogin={this.afterLogin}
                    />
                  )}
                />
                <Route
                  exact
                  path="/rd/password/reset/:uid/:token"
                  render={(props) => (
                    <ResetPasswordConfirm
                      {...props}
                      player={this.state.player}
                      loggedIn={this.state.loggedIn}
                      afterLogin={this.afterLogin}
                    />
                  )}
                />
                <Route
                  exact
                  path="/rd/password/reset"
                  render={(props) => (
                    <ResetPassword {...props} loggedIn={this.state.loggedIn} />
                  )}
                />
                <Route
                  exact
                  path="/leagues"
                  render={(props) => (
                    <Leagues
                      {...props}
                      player={this.state.player}
                      loggedIn={this.state.loggedIn}
                    />
                  )}
                />
                <Route
                  exact
                  path="/league/:slug"
                  render={(props) => (
                    <League
                      {...props}
                      player={this.state.player}
                      loggedIn={this.state.loggedIn}
                    />
                  )}
                />
                <Route
                  exact
                  path="/league/optout/:token"
                  render={(props) => (
                    <LeagueOptout
                      {...props}
                      player={this.state.player}
                      loggedIn={this.state.loggedIn}
                    />
                  )}
                />
                <Route
                  exact
                  path="/league/:slug/:token"
                  render={(props) => (
                    <League
                      {...props}
                      afterLogin={this.afterLogin}
                      player={this.state.player}
                      loggedIn={this.state.loggedIn}
                    />
                  )}
                />
                <Route
                  exact
                  path="/:handle"
                  render={(props) => (
                    <Player {...props} self={this.state.player} />
                  )}
                />
                <Route
                  exact
                  path="/rd/unsubscribe/digest/:token"
                  render={(props) => (
                    <DigestUnsubscribe
                      {...props}
                      player={this.state.player}
                      loggedIn={this.state.loggedIn}
                    />
                  )}
                />
                <Route
                  exact
                  path="/rd/unsubscribe/reminders/:token"
                  render={(props) => (
                    <ReminderUnsubscribe
                      {...props}
                      player={this.state.player}
                      loggedIn={this.state.loggedIn}
                    />
                  )}
                />
                <Route
                  exact
                  path="/rd/scorehistory/:handle"
                  render={(props) => (
                    <ScoreHistory {...props} self={this.state.player} />
                  )}
                />
                <Route
                  exact
                  path="/rd/bethistory/:handle"
                  render={(props) => (
                    <BetHistory {...props} self={this.state.player} />
                  )}
                />
                <Route
                  exact
                  path="/rd/digest"
                  render={(props) => (
                    <SendDigest
                      {...props}
                      player={this.state.player}
                      loggedIn={this.state.loggedIn}
                      foo="foo"
                    />
                  )}
                />

                <Route component={FileNotFound} />
              </Switch>
            </Suspense>
          </section>
        </main>
        <Footer />
      </Fragment>
    );
  }
}

App.propTypes = {
  player: PropTypes.object,
  loggedIn: PropTypes.bool,
  scrolled: PropTypes.bool,
  showSidebarLogin: PropTypes.bool,
  betMessages: PropTypes.array,
};

Route.propTypes = {
  computedMatch: PropTypes.object,
  path: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  sensitive: PropTypes.bool,
  component: PropTypes.func,
  render: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  location: PropTypes.object,
};

export default withRouter((props) => <App {...props} />);
