import React from "react";

import variables from "../sass/_variables.scss";

// Option gradient parameter to put radial gradient in background.
// (Doesn't read well at small sizes.)
// To use: <Logo gradient="true" />

export const Logo = ({
  gradient = false,
  foreground = "#ffffff",
  background = variables.colorSecondary,
  stroke = "#fafafa"
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 308.2 347.9">
      {gradient && (
        <defs>
          <radialGradient
            id="a"
            cx="152.63"
            cy="295.82"
            r="184.39"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.01" stopColor="#fff" />
            <stop offset="0.2" stopColor="#fff" />
            <stop offset="1" stopColor="#ddd" />
          </radialGradient>
        </defs>
      )}
      <g className="logo__group">
        <path
          fill={background}
          d="M292.1,64.7l-40-39.6H51.7l-40,39.6S35.3,97,35.3,132.1s-15.1,60.3-8.4,98.4,22.5,62.4,64,78.3c50.2,19.2,61,39.1,61,39.1s10.8-19.9,61-39.1c41.5-15.9,57.3-30.1,64-68.3s-8.4-73.3-8.4-108.4S292.1,64.7,292.1,64.7Z"
        />
        <path
          fill={stroke}
          d="M257.7,132.1c0-27.8,13.1-53.5,20.6-65.9L247.6,35.9H56.1L25.5,66.2C33,78.6,46,104.3,46,132.1c0,15.5-2.7,29-5.3,42.1-3.5,17.5-6.8,34.1-3.2,54.5,5.7,32.5,17.5,54.9,57.2,70,30.8,11.8,48,24.1,57.2,32.7h0c9.2-8.6,26.3-21,57.2-32.7,39.7-15.2,51.5-27.5,57.2-60,3.6-20.4.3-47-3.2-64.5C260.4,161.1,257.7,147.6,257.7,132.1Z"
        />
        <path
          fill={background}
          d="M33.6,67.2c8,14.3,18.9,38.6,18.9,64.9,0,16.2-2.8,30-5.4,43.3-3.4,16.9-6.6,32.9-3.2,52.1,5.3,30,15.7,50.8,53.1,65.1,27.6,10.6,44.7,21.7,54.8,30.2V42.3H58.8Z"
        />
        <path
          fill={background}
          d="M151.9,322.8c10.2-8.5,27.2-19.6,54.8-30.2,37.4-14.3,47.9-25.1,53.1-55.1,3.4-19.2.2-45.2-3.2-62.1-2.7-13.3-5.4-27.1-5.4-43.3,0-26.3,10.9-50.6,18.9-64.9L245,42.3H151.9V322.8h0Z"
        />
        <path
          className="logo__gradient"
          d="M184.6,302.2q5-2.5,10.8-5l-41.5-1.1H154c0.4,0,.4-0.1.3-0.1s0-.1-0.3-0.1H154l66.3-8.8c13.5-6.1,22.3-12.1,28.4-19.9l-94.8,28.5h0.2c0.3-.1.4-0.2,0.2-0.2s0-.1-0.3,0h-0.1l104.6-50.7c0.5-2.2,1-4.6,1.4-7.1a108.3,108.3,0,0,0,1.4-17.9L153.5,295.1V295c0.3-.2.3-0.3,0.2-0.3s0-.1-0.3.1h-0.1l105.9-103c-0.8-6-1.7-11.6-2.7-16.4s-2.1-10.5-3-15.9L153.2,294.7v-0.2c0.2-.3.2-0.4,0.1-0.3s-0.1-.1-0.2.2H153l108-208.2a168.7,168.7,0,0,1,9.2-19.1L245,42.3h-9.4L152.7,294.4s0-.1,0-0.1,0.1-.4,0-0.3-0.1-.1-0.2.3v0.2L192.3,42.3H151.4l0.7,252h0c0-.4-0.1-0.5-0.1-0.3s-0.1,0-.1.3v0.2L110.4,42.3H67.2l84.3,252h0c-0.1-.3-0.2-0.4-0.2-0.3s-0.1,0,0,.3v0.2L44.2,90c4.6,12.3,8.3,26.8,8.3,42.1a165.6,165.6,0,0,1-2.4,27.3L151,294.6c-0.5-.4-0.6-0.3.3,0.7h-0.1L44.2,191.1a114.4,114.4,0,0,0-1.4,28l107.8,76h0c-0.3-.2-0.4-0.2-0.3-0.1s-0.1.1,0.2,0.3l0.2,0.2L48,245a69.3,69.3,0,0,0,11.6,22.6l90.8,28h0c-0.4-.1-0.5-0.1-0.4,0s-0.1.1,0.3,0.2h0.2l-68-9.8A123.5,123.5,0,0,0,97,292.6l9.9,4,43.3-.5h0c-0.4,0-.5.1-0.3,0.1s0,0.1.3,0.1h0.3l-32.2,5.4,6.6,3.4,25.5-8.5h0c-0.3.1-.4,0.2-0.3,0.2s0,0.1.3,0h0.2l-21,10.9,5.9,3.4,6-4.5-5.1,5.1,2.9,1.8,9.3-12.7,2.1-2.1-8.6,16.6,2.9,2,6.6-19.6h0s0,0.1,0,.1-0.1.5,0,.3,0.1,0.1.2-.3h0.1l-3.8,21.9,3.6,2.9,0.4-24.8V298c0,0.4.1,0.5,0.1,0.3s0.1,0,.1-0.3h0l3,22,3.2-2.4-6.1-20.3h0c0.4,1.3.5,1.2,0.4,0.6l8.5,17.8,2.8-1.9-11.1-16.2,0.2,0.2c0.2,0.3.3,0.3,0.3,0.2s0.1,0-.1-0.3h0L167,311.9l3.1-1.9-16.7-12.9h0.3c0.3,0.2.4,0.2,0.3,0.1s0.1-.1-0.2-0.2h0l19.9,10.9,4.5-2.5-24.6-8.8h0.3c0.4,0.1.4,0.1,0.3,0s0.1-.1-0.3-0.2h0Z"
        />
        <path
          fill={foreground}
          d="M307.8,122.7c-4.1-15.8-8.2-31.6-16.1-46.1-9-16.5-18.3-32.9-21.6-51.9-0.7-4.2-3.2-5.8-7.4-4.2-1.8.7-3.9,1.9-5.7,1-4.5-2.1-7.1.7-8.7,3.8s-5.8,9.8-2.3,14.1,6,9.7,10.7,13.3a21.9,21.9,0,0,1,6.5,8.4,146.7,146.7,0,0,1,11.7,41.8c0.8,5.6-1.2,7.2-6.9,5.7s-13.7-3.1-20.7-2.4a16.5,16.5,0,0,1-8.3-1.3c-10.4-4.2-21.4-8.2-32.3-4s-17.2.4-25.3-2.6c-4.3-1.6-6.6-5.2-5.6-9.9,1.4-6.8,2.5-13.6,6.6-19.6,2.7-4,7-8.4,4.5-13.7s-0.9-7.7,1.2-11.4c6.9-12.1,1-32.6-11.1-38.6C169.5,1.4,161.6-.8,153.4.3c-13,1.6-24.2,5.6-28.9,20-2.8,8.7-1.3,16.1,2.4,23.7,1,2,2.6,4.2.1,6.3s-2.6,5.5-.9,8.7c0.9,1.6,2.2,3.2,1.8,5-2.3,9.6,2.1,17,7.1,24.4,1.9,2.7,2.4,6.9-.9,7.9-7.2,2.3-14.8,5.6-22.4,2.5-9.7-4-19.3-5.1-29.4-2.3-7.4,2-14.6,4.8-22.6,3.9-5.4-.6-10.7.3-16,1.6s-8.9-.5-8.4-7.1a133.5,133.5,0,0,1,7.5-34.6c3.2-9.2,9.6-16.4,17-22.8a14.2,14.2,0,0,0,4.7-13c-1.3-8.5-4.8-10.7-16-11.3-3.5-.2-5.5.9-6.7,4.5A199.7,199.7,0,0,1,28.6,48.3C20.6,63.1,10.9,77.2,7.5,94.1a76.2,76.2,0,0,1-6.1,17.7c-4,8.2,1.2,17.2,10.2,18.3A60.1,60.1,0,0,1,21,132c16.2,4.8,32.3,7.4,49.2,3.4,12.9-3.1,16.7-.3,19.7,12.7,0.4,1.6.4,3.2,0.9,4.8,3.5,10.6,1.3,21.8,3.5,32.5A67.8,67.8,0,0,1,96,198.1c0.1,13.2.5,26.5-6.5,38.5-0.6,1-.4,2.5-0.9,3.6-3.7,9-4.2,19.1-10.7,27.3-2.7,3.5-6.1,7.4-6.5,12.5a78,78,0,0,1-3.2,17.7,132.5,132.5,0,0,0,22.6,11c50.2,19.2,61,39.1,61,39.1s10.8-19.9,61-39.1a228.8,228.8,0,0,0,21.7-9.4l-2.5-2.8c-12-12.6-27.4-18.7-43.5-23.5-4.5-1.3-6.2-3.3-5.9-7.8,1-16.5,6.8-31.7,13-46.7,3.4-8.5,7.4-16.7,10.2-25.4,4.6-14,8.4-28.2,13.8-41.9,3.7-9.2,7.9-11.5,17.5-9.2,13.2,3.3,26.5,4.4,40,2.1,7.3-1.2,14.5-2.5,21.8-3.3,4.1-.4,6.2-2.5,7.4-6S308.8,126.8,307.8,122.7Z"
        />
      </g>
    </svg>
  );
};
