// Third-party libraries
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

// Project dependencies
import { getHeaders, passwordResetUrl } from "../apihelpers";
import { Meta } from '../utilities';

class ResetPassword extends Component {
  state = {
    email: "",
    submitted: false
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      email: event.target.value
    });
  }

  handleSubmit(event) {
    this.setState({ submitted: true });
    const headers = getHeaders();
    fetch(passwordResetUrl, {
      method: "POST",
      headers,
      body: JSON.stringify({ email: this.state.email })
    })
      .catch(error => console.log(error));
    event.preventDefault();
  }

  render() {
    if (this.state.submitted) {
      return (
        <Fragment>
          <h1>Reset Password</h1>
          <p>
            A link to set a new password will be sent to the email address you
            submitted. If you need further assistance, contact Rouleur Derby at{" "}
            <a href="mailto:info@rouleurderby.com">info@rouleurderby.com</a>.
          </p>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Meta title="Reset Password" description="Enter your email to get a link for resetting your password." />
         
        <h1>Reset Password</h1>
        <form onSubmit={this.handleSubmit}>
          <label htmlFor="email">Email</label>
          <input
            id="email"
            onChange={this.handleChange}
            type="email"
            name="email"
            value={this.state.email}
          />
          <button className="button--clear">Request new password</button>
        </form>
      </Fragment>
    );
  }
}

ResetPassword.propTypes = {
    email: PropTypes.string,
    submitted: PropTypes.bool
  };

export default ResetPassword;
