// Third-party libraries
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Project dependencies
import PropositionItem from "../components/PropositionItem";
import { getHeaders, propositionsUrl } from "../apihelpers";
import { Meta } from "../utilities";
import { Invite, LoadingBar } from "../components/widgets";

class PropositionMenu extends Component {
  state = {
    propositions: [],
    loading: false,
  };

  _isMounted = false;

  getPropositions() {
    if (localStorage.propositions) {
      if (this._isMounted) {
        this.setState({
          propositions: JSON.parse(localStorage.getItem("propositions")),
        });
      }
    } else {
      if (this._isMounted) {
        this.setState({ loading: true });
      }
    }

    let headers = getHeaders(true);
    fetch(propositionsUrl + "?" + Date.now(), {
      method: "GET",
      headers,
    })
      .then((res) => res.json())
      .then((data) => {
        if (this._isMounted) {
          this.setState({ propositions: data, loading: false });
        }
        localStorage.setItem("propositions", JSON.stringify(data));
        // Suspend preloading untion proposedBets can be ironed out
        this.preloadPropositions();
      })
      .catch((error) => console.log(error));
  }

  preloadPropositions() {
    let headers = getHeaders(true);
    fetch(propositionsUrl + "/open", {
      method: "GET",
      headers,
    })
      .then((res) => res.json())
      .then((propositions) => {
        if (this._isMounted) {
          propositions.map((proposition) => {
            localStorage.setItem(proposition.slug, JSON.stringify(proposition));
            let proposedBets = [];
            proposition.options.forEach((option) => {
              proposedBets.push({ option_data_id: option.id, amount: 0 });
            });
            localStorage.setItem(
              proposition.slug + "-proposedBets",
              JSON.stringify(proposedBets)
            );
            return true;
          });
        }
      });
  }

  componentDidMount() {
    this._isMounted = true;
    this.getPropositions();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const featuredProp = this.state.propositions.filter((prop) => {
      return prop.coming && prop.featured && prop.status === 2;
    })[0];

    const crossProps = this.state.propositions.filter((prop) => {
      return (
        prop.status === 2 &&
        prop.coming &&
        prop.contest &&
        prop.contest.name === "Cyclocross" &&
        (!featuredProp || prop.id !== featuredProp.id)
      );
    });

    const springProps = this.state.propositions.filter((prop) => {
      return (
        prop.status === 2 &&
        prop.coming &&
        prop.contest &&
        prop.contest.name.indexOf("Classics") >= 0 &&
        (!featuredProp || prop.id !== featuredProp.id)
      );
    });

    const tourProps = this.state.propositions.filter((prop) => {
      return (
        prop.status === 2 &&
        prop.coming &&
        prop.contest &&
        prop.contest.name === "Tour de France" &&
        (!featuredProp || prop.id !== featuredProp.id)
      );
    });

    const giroProps = this.state.propositions.filter((prop) => {
      return (
        prop.status === 2 &&
        prop.coming &&
        prop.contest &&
        prop.contest.name.indexOf("Giro") >= 0 &&
        (!featuredProp || prop.id !== featuredProp.id)
      );
    });

    const vueltaProps = this.state.propositions.filter((prop) => {
      return (
        prop.status === 2 &&
        prop.coming &&
        prop.contest &&
        prop.contest.name.indexOf("Vuelta") >= 0 &&
        (!featuredProp || prop.id !== featuredProp.id)
      );
    });

    const worldsProps = this.state.propositions.filter((prop) => {
      return (
        prop.status === 2 &&
        prop.coming &&
        prop.contest &&
        prop.contest.name.indexOf("World") >= 0 &&
        (!featuredProp || prop.id !== featuredProp.id)
      );
    });

    const otherProps = this.state.propositions.filter((prop) => {
      return (
        prop.status === 2 &&
        prop.coming &&
        !prop.contest &&
        (!featuredProp || prop.id !== featuredProp.id)
      );
    });

    const pendingProps = this.state.propositions.filter(
      (prop) => prop.status === 2 && !prop.coming
    );
    const scoredProps = this.state.propositions
      .filter((prop) => prop.status === 4)
      .reverse();

    if (this.state.loading) {
      return <LoadingBar />;
    } else if (this.state.propositions.length > 0) {
      return (
        <Fragment>
          <Meta
            title="Rouleur Derby Fantasy Cycling Game"
            description="Rouleur Derby is almost certainly the best fantasy cycling game in the world."
          />
          {this.props.player.bet_tally % 10 === 0 && (
            <Invite player={this.props.player} />
          )}

          {featuredProp && (
            <Fragment>
              <h2 className="proposition-menu__h2 proposition-menu__h2--featured">
                <span>Featured Proposition</span>
              </h2>
              <div className="proposition-menu proposition-menu--featured">
                <PropositionItem
                  player={this.props.player}
                  proposition={featuredProp}
                />
              </div>
            </Fragment>
          )}

          <PropGroup
            title="Cyclocross"
            link="/contest/cyclocross"
            player={this.props.player}
            propositions={crossProps}
          />

          <PropGroup
            title="Spring Classics"
            link="/contest/spring-classics"
            player={this.props.player}
            propositions={springProps}
          />
          <PropGroup
            title="Giro d’Italia"
            link="/contest/giro-italia"
            player={this.props.player}
            propositions={giroProps}
          />

          <PropGroup
            title="Tour de France"
            link="/contest/tour-de-france"
            player={this.props.player}
            propositions={tourProps}
          />

          <PropGroup
            title="World Championships"
            link="/contest/worlds"
            player={this.props.player}
            propositions={worldsProps}
          />

          <PropGroup
            title="Vuelta a España"
            link="/contest/vuelta-a-espana"
            player={this.props.player}
            propositions={vueltaProps}
          />

          <PropGroup
            title="Other Propositions"
            player={this.props.player}
            propositions={otherProps}
          />

          <div className="proposition-menu__sections">
            {pendingProps.length > 0 && (
              <section className="proposition-menu__section">
                <h3 className="proposition-menu__h3">
                  Propositions waiting{" "}
                  <span className="nobr">to be scored</span>
                </h3>
                <ul>
                  {pendingProps.map((proposition, i) => (
                    <li key={i} className="proposition-menu__list-item">
                      <Link to={`/prop/${proposition.slug}`}>
                        {proposition.question}
                      </Link>
                    </li>
                  ))}
                </ul>
              </section>
            )}
            {scoredProps.length > 0 && (
              <section className="proposition-menu__section">
                <h3 className="proposition-menu__h3">
                  Recently scored propositions
                </h3>
                {scoredProps.length > 0 && (
                  <Fragment>
                    <ul>
                      {scoredProps.map((proposition, i) => {
                        if (i < 10) {
                          return (
                            <li key={i} className="proposition-menu__list-item">
                              <Link to={`/prop/${proposition.slug}`}>
                                {proposition.question}
                              </Link>
                            </li>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </ul>
                  </Fragment>
                )}
                <p>
                  <Link to="/rd/archive">See all</Link>
                </p>
              </section>
            )}
          </div>
        </Fragment>
      );
    } else {
      return (
        <>
          <Meta
            title="Rouleur Derby Fantasy Cycling Game"
            description="Rouleur Derby is almost certainly the best fantasy cycling game in the world."
          />
          <aside className="alert">
            The 2020-2021 season of Rouleur Derby will be starting very, very
            soon.
          </aside>
        </>
      );
    }
  }
}

function PropGroup({ player, propositions, title, link }) {
  if (propositions.length === 0) {
    return null;
  }
  return (
    <Fragment>
      <h2 className="proposition-menu__h2">
        {(link && (
          <span>
            <Link to={link}>{title}</Link>
          </span>
        )) || <span>{title}</span>}
      </h2>
      <div className="proposition-menu">
        {propositions.map((proposition, i) => (
          <PropositionItem key={i} player={player} proposition={proposition} />
        ))}
      </div>
    </Fragment>
  );
}

PropositionMenu.propTypes = {
  propositions: PropTypes.array,
};

export default PropositionMenu;
