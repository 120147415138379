import React from "react";
import { Link } from "react-router-dom";
import { intComma } from "humanize-plus";
import { oddsToString, payoutToOdds } from "../utilities";
import { Bet } from "../shared/interfaces";

interface Props {
  bet: Bet;
}

const BetItem = ({ bet }:Props) => {
  return (
    <tr>
      <td>
        <Link to={`/prop/${bet.proposition.slug}`}>{bet.proposition.name}</Link>
      </td>
      <td>{bet.proposition.date_string}</td>
      <td>{bet.selection.name}</td>
      <td>{oddsToString(payoutToOdds(bet.pre_payout))}</td>
      <td className="figure">{intComma(bet.amount)}</td>
    </tr>
  );
}

export default BetItem;
