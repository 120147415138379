import React from "react";

import variables from "../sass/_variables.scss";
const CancelIcon = ({color=variables.colorError, foreground="#ffffff"}) => (
  <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" viewBox="0 0 50 50">
    <circle cx="25" cy="25" r="25" fill={color} />
    <polyline
      points="16,34 25,25 34,16"
      fill="none"
      stroke={foreground}
      strokeWidth="2"
      strokeLinecap="round"
      strokeMiterlimit="10"
    />
    <polyline
      points="16,16 25,25 34,34"
      fill="none"
      stroke={foreground}
      strokeWidth="2"
      strokeLinecap="round"
      strokeMiterlimit="10"
    />
  </svg>
);

export default CancelIcon;
