// Third-party libraries
import React, { Fragment, useState } from "react";
import { intComma, pluralize } from "humanize-plus";
import { Link } from "react-router-dom";

// Project dependencies
import { Bet, Proposition } from "../../shared/interfaces";
import { oddsToString } from "../../utilities";
import SuccessIcon from "../../svg/SuccessIcon";
import CancelIcon from "../../svg/CancelIcon";

function ResolutionSet({
  proposition,
  bets
}: {
  proposition: Proposition;
  bets: Array<Bet>;
}) {
  const maxInitialShown = 4;
  const winners = bets.filter(bet => bet.status === 5);
  const losers = bets.filter(bet => bet.status === 4);
  const totalGross = bets.reduce((a, b) => a + b.gross, 0);
  const totalWaged = bets.reduce((a, b) => a + b.amount, 0);
  const net = totalGross - totalWaged;

  return (
    <Link
      to={`/prop/${proposition.slug}`}
      className="prop-card resolution-card"
    >
      <article>
        {proposition.race &&
          (proposition.contest &&
            proposition.race.name !== proposition.contest.name) && (
            <div className="prop-card__race">{proposition.race.name}</div>
          )}
        <h1 className="prop-card__question">{proposition.question}</h1>
        <div className="prop-card__meta">
          <div className="prop-card__meta-item">
            <strong>{net > 0 ? "Total gain" : "Total loss"}</strong>
            {intComma(net)}
          </div>
          {winners.length > 0 && (
            <div className="prop-card__meta-item prop-card__meta-item--full-width">
              <strong>Your winning {pluralize(winners.length, "bet")}</strong>
              <ul className="resolution-list">
                {winners
                  .slice(
                    0,
                    winners.length <= maxInitialShown
                      ? maxInitialShown
                      : maxInitialShown - 1
                  )
                  .map((bet, i) => (
                    <li className="resolution-item" key={i}>
                      <span className="resolution-icon">
                        <SuccessIcon />
                      </span>
                      {intComma(bet.amount)} on {bet.selection.name} at{" "}
                      {oddsToString(bet.odds)}
                    </li>
                  ))}
                {winners.length > maxInitialShown && (
                  <li className="resolution-item resolution-item__more">
                    … and {winners.length - maxInitialShown + 1} more
                  </li>
                )}
              </ul>
            </div>
          )}
          {losers.length > 0 && (
            <div className="prop-card__meta-item prop-card__meta-item--full-width">
              <strong>Your losing {pluralize(losers.length, "bet")}</strong>
              <ul className="resolution-list">
                {losers
                  .slice(
                    0,
                    losers.length <= maxInitialShown
                      ? maxInitialShown
                      : maxInitialShown - 2
                  )
                  .map((bet, i) => (
                    <li className="resolution-item" key={i}>
                      <span className="resolution-icon">
                        <CancelIcon />
                      </span>
                      {intComma(bet.amount)} on {bet.selection.name} at{" "}
                      {oddsToString(bet.odds)}
                    </li>
                  ))}
                {losers.length > maxInitialShown && (
                  <li className="resolution-item  resolution-item__more">
                    … and {losers.length - maxInitialShown + 2} more
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      </article>
    </Link>
  );
}

export default ResolutionSet;
