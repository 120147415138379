// Third-party libraries
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { intComma } from "humanize-plus";

// Project dependencies
import { allBetsUrl, getHeaders, propositionsUrl } from "../apihelpers";
import { Meta, PropWinners } from "../utilities";
import { LoadingBar } from "../components/widgets";

class Archive extends Component {
  state = {
    loading: false,
    proposition: []
  };

  _isMounted = false;

  getPropositions() {
    if (localStorage.propositions) {
      if (this._isMounted) {
        this.setState({
          propositions: JSON.parse(localStorage.getItem("propositions"))
        });
      }
    }

    let headers = getHeaders(true);
    fetch(propositionsUrl, {
      method: "GET",
      headers
    })
      .then(res => res.json())
      .then(data => {
        if (this._isMounted) {
          this.setState({ propositions: data.reverse() }, () => {
            if (this.props.player && this.props.loggedIn) {
              this.getBets();
            }
            return true;
          });
        }
        localStorage.setItem("propositions", JSON.stringify(data));
      })
      .catch(error => console.log(error));
  }

  getBets() {
    if (localStorage.allBets) {
      if (this._isMounted) {
        this.setState({
          bets: JSON.parse(localStorage.getItem("allBets"))
        });
      }
    }
    let headers = getHeaders();
    fetch(allBetsUrl, {
      method: "GET",
      headers
    })
      .then(res => res.json())
      .then(data => {
        if (this._isMounted) {
          this.setState({ bets: data });
        }
        localStorage.setItem("allBets", JSON.stringify(data));
      })
      .catch(error => console.log(error));
  }

  componentDidMount() {
    this._isMounted = true;
    this.getPropositions();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    if (this.state.loading) {
      return (
        <Fragment>
          <LoadingBar />
        </Fragment>
      );
    } else if (
      this.state.propositions &&
      this.state.propositions.filter(prop => prop.status === 4).length > 0
    ) {
      const crossProps = this.state.propositions.filter(prop => {
        return (
          prop.status === 4 &&
          prop.contest &&
          prop.contest.name === "Cyclocross"
        );
      });

      const springProps = this.state.propositions.filter(prop => {
        return (
          prop.status === 4 &&
          prop.contest &&
          prop.contest.name === "Spring Classics"
        );
      });

      const tourProps = this.state.propositions.filter(prop => {
        return (
          prop.status === 4 &&
          prop.contest &&
          prop.contest.name === "Tour de France"
        );
      });

      const giroProps = this.state.propositions.filter(prop => {
        return (
          prop.status === 4 &&
          prop.contest &&
          prop.contest.name.indexOf("Giro") >= 0
        );
      });

      const vueltaProps = this.state.propositions.filter(prop => {
        return (
          prop.status === 4 &&
          prop.contest &&
          prop.contest.name.indexOf("Vuelta") >= 0
        );
      });

      const worldsProps = this.state.propositions.filter(prop => {
        return (
          prop.status === 4 &&
          prop.contest &&
          prop.contest.name.indexOf("World") >= 0
        );
      });

      const otherProps = this.state.propositions.filter(prop => {
        return prop.status === 4 && !prop.contest;
      });
      return (
        <Fragment>
          <Meta
            title="Rouleur Derby Archive"
            description="Scored propositions from Rouleur Derby, almost certainly the best fantasy cycling game in the world."
          />
          <h1>Proposition Archive</h1>
          <PropGroup
            bets={this.state.bets}
            title="World Championships"
            link="/contest/worlds"
            loggedIn={this.props.loggedIn}
            player={this.props.player}
            propositions={worldsProps}
          />
          <PropGroup
            bets={this.state.bets}
            title="Vuelta a España"
            link="/contest/vuelta-a-espana"
            loggedIn={this.props.loggedIn}
            player={this.props.player}
            propositions={vueltaProps}
          />
          <PropGroup
            bets={this.state.bets}
            title="Tour de France"
            link="/contest/tour-de-france"
            loggedIn={this.props.loggedIn}
            player={this.props.player}
            propositions={tourProps}
          />
          <PropGroup
            bets={this.state.bets}
            title="Giro d’Italia"
            link="/contest/giro-italia"
            loggedIn={this.props.loggedIn}
            player={this.props.player}
            propositions={giroProps}
          />
          <PropGroup
            bets={this.state.bets}
            title="Spring Classics"
            link="/contest/spring-classics"
            player={this.props.player}
            loggedIn={this.props.loggedIn}
            propositions={springProps}
          />

          <PropGroup
            bets={this.state.bets}
            title="Cyclocross"
            link="/contest/cyclocross"
            player={this.props.player}
            loggedIn={this.props.loggedIn}
            propositions={crossProps}
          />

          <PropGroup
            bets={this.state.bets}
            title="Other Propositions"
            loggedIn={this.props.loggedIn}
            player={this.props.player}
            propositions={otherProps}
          />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <p>No propositions yet</p>
        </Fragment>
      );
    }
  }
}

function PropGroup({ player, propositions, loggedIn, title, link, bets }) {
  if (propositions.length === 0) {
    return null;
  }
  return (
    <Fragment>
      <h2 className="proposition-menu__h2">
        {(link && (
          <span>
            <Link to={link}>{title}</Link>
          </span>
        )) || <span>{title}</span>}
      </h2>
      <div className="sticky-table-outer">
        <table
          className={`table--sticky proposition-menu--archive proposition-menu--grid ${player &&
            loggedIn &&
            bets &&
            bets.length > 0 &&
            "proposition-menu--archive-with-action"}`}
        >
          <thead>
            <tr>
              <th className="proposition-grid__prop">Proposition</th>
              <th className="proposition-grid__date">Date</th>

              <th className="proposition-grid__winner">Winner</th>
              {player && loggedIn && bets && bets.length > 0 && (
                <th className="proposition-grid__action figure">Your net</th>
              )}
            </tr>
          </thead>
          <tbody>
            {propositions.map((proposition, i) => (
              <tr key={i}>
                <td className="proposition-grid__prop">
                  <Link to={`/prop/${proposition.slug}`}>
                    {proposition.question}
                  </Link>
                </td>

                <td className="proposition-grid__date">
                  {proposition.date_string.split(", ")[1]}
                </td>
                <td className="proposition-winner">
                  <PropWinners proposition={proposition} withOdds={true} />
                </td>
                {player && loggedIn && bets && bets.length > 0 && (
                  <td className="proposition-grid__action figure">
                    <PlayerNet proposition={proposition} bets={bets} />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}

function PlayerNet({ proposition, bets }) {
  const thisPropsBets = bets.filter(
    bet => bet.proposition.slug === proposition.slug
  );

  let net = 0;
  thisPropsBets.map(bet => {
    if (bet.status === 4) {
      //loser
      net -= bet.amount;
    } else if (bet.status === 5) {
      // winner
      net += bet.profit;
    }
    return true;
  });
  return intComma(net);
}

Archive.propTypes = {
  propositions: PropTypes.array
};

export default Archive;
