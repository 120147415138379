export const CONTESTS = [
  { slug: "cyclocross", name: "Cyclocross" },
  {
    slug: "spring-classics",
    name: "Spring Classics",
  },
  {
    slug: "giro-italia",
    name: "Giro d’Italia",
  },
  {
    slug: "tour-de-france",
    name: "Tour de France",
  },
  {
    slug: "vuelta-a-espana",
    name: "Vuelta a España",
  },
  { slug: "worlds", name: "World Championships" },
  { slug: "other", name: "Other Propositions" },
];

// Bet limits
export const MINIMUM_ALLOWED = 50;
export const PCT_MAX = 10; 