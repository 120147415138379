import React from "react";
import variables from "../sass/_variables.scss";

const SlackIcon = ({ color = variables.colorBody }) => (
  <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg'
viewBox='0 0 800 800'>
    <path fill={color} d='M168.991,505.122c0,46.073-37.636,83.71-83.708,83.71s-83.708-37.637-83.708-83.71c0-46.072,37.636-83.709,83.708-83.709 h83.708V505.122z'
    />
    <path fill={color} d='M211.168,505.122c0-46.072,37.638-83.709,83.709-83.709c46.072,0,83.711,37.637,83.711,83.709v209.596 c0,46.071-37.639,83.708-83.711,83.708c-46.071,0-83.709-37.637-83.709-83.708V505.122z'
    />
    <path fill={color} d='M294.877,168.991c-46.071,0-83.709-37.636-83.709-83.708s37.638-83.708,83.709-83.708 c46.072,0,83.711,37.636,83.711,83.708v83.708H294.877z'
    />
    <path fill={color} d='M294.877,211.169c46.072,0,83.711,37.636,83.711,83.708s-37.639,83.708-83.711,83.708H85.283 c-46.072,0-83.708-37.636-83.708-83.708s37.636-83.708,83.708-83.708H294.877z'
    />
    <g>
        <path fill={color} d='M631.009,294.878c0-46.072,37.637-83.708,83.709-83.708c46.071,0,83.708,37.636,83.708,83.708 s-37.637,83.708-83.708,83.708h-83.709V294.878z'
        />
        <path fill={color} d='M588.832,294.878c0,46.072-37.637,83.708-83.709,83.708s-83.711-37.636-83.711-83.708V85.283 c0-46.072,37.639-83.708,83.711-83.708s83.709,37.636,83.709,83.708V294.878z'
        />
    </g>
    <g>
        <path fill={color} d='M505.123,631.009c46.072,0,83.709,37.637,83.709,83.709c0,46.071-37.637,83.708-83.709,83.708 s-83.711-37.637-83.711-83.708v-83.709H505.123z'
        />
        <path fill={color} d='M505.123,588.832c-46.072,0-83.711-37.637-83.711-83.71c0-46.072,37.639-83.709,83.711-83.709h209.595 c46.071,0,83.708,37.637,83.708,83.709c0,46.073-37.637,83.71-83.708,83.71H505.123z'
        />
    </g>
</svg>
);

export default SlackIcon;
