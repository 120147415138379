// Third-party libraries
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { intComma } from "humanize-plus";

// Project dependencies
import { LoadingBar } from "../components/widgets";
import { getHeaders, contestUrl, errorHandler } from "../apihelpers";
import { Meta } from "../utilities";
import PropositionItem from "../components/PropositionItem";
import variables from "../sass/_variables.scss";
import WapIcon from "../svg/WapIcon";

class Contest extends Component {
  slug = this.props.match.params.slug;
  state = { contest: {}, players: [] };

  _isMounted = false;

  getContest() {
    if (localStorage.getItem(`contest-${this.slug}`)) {
      const storage = JSON.parse(localStorage.getItem(`contest-${this.slug}`));
      if (this._isMounted) {
        this.setState({
          contest: storage.contest,
          players: storage.players
        });
      }
    }

    const headers = getHeaders();
    fetch(contestUrl + "/" + this.slug + "?" + Date.now(), {
      method: "GET",
      headers
    })
      .then(errorHandler)
      .then(data => {
        if (this._isMounted) {
          this.setState({ contest: data.contest, players: data.players });
        }
        localStorage.setItem(`contest-${this.slug}`, JSON.stringify(data));
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidMount() {
    this._isMounted = true;
    this.getContest();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (!this.state.contest.name) {
      return (
        <Fragment>
          <LoadingBar />
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Meta
            title={`${this.state.contest.name} Leaders`}
            description={`These are the current leaders in Rouleur Derby’s ${this.state.contest.name}.`}
          />
          <h1>{this.state.contest.name} Leaders</h1>
          <p>
            In contests, players are ranked by return on investment, not raw
            points. To be ranked, players must risk a minimum of{" "}
            {this.state.contest.minimum_waged} points and make{" "}
            {this.state.contest.minimum_bets} bets. However, figures on this
            leaderboard reflect only bets that have been resolved.
          </p>
          {this.state.players && this.state.players.length > 0 && (
            <div className="sticky-table-outer">
              <table className="contest__table table--sticky table--rank">
                <thead>
                  <tr>
                    <th>Player</th>
                    <th className="figure">Return on investment</th>
                    <th className="figure">Bets in this contest</th>
                    <th className="figure">Points bet in this contest</th>
                    <th className="figure">Profit in this contest </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.players &&
                    this.state.players
                      .sort((a, b) => b.roi - a.roi)
                      .map((player, i) => {
                        if (i === 0) {
                          player.rank = 1;
                          return player;
                        } else if (
                          player.roi !== this.state.players[i - 1].roi
                        ) {
                          player.rank = i + 1;
                          return player;
                        } else {
                          player.rank = "-\u00A0";
                          return player;
                        }
                      })
                      .map((player, i) => (
                        <PlayerItem
                          key={i}
                          player={player}
                          visitor={this.props.player}
                        />
                      ))}
                </tbody>
              </table>
            </div>
          )}
          {this.state.players && this.state.players.length === 0 && (
            <p>No players have qualified for this contest yet.</p>
          )}

          <h2 className="proposition-menu__h2" style={{ marginTop: "8rem" }}>
            <span>Propositions in this contest</span>
          </h2>
          <div className="proposition-menu">
            {this.state.contest.propositions
              .filter(
                proposition =>
                  proposition.status === 2 || proposition.status === 4
              )
              .map((proposition, i) => (
                <PropositionItem key={i} proposition={proposition} />
              ))}
          </div>
        </Fragment>
      );
    }
  }
}

function PlayerItem({ player, visitor }) {
  return (
    <tr
      className={player.id && player.id === visitor.id ? "rank-row--self" : ""}
    >
      <td>
        <div className="rank-cell">
          <div className="rank-cell__rank">
            {typeof player.rank === "number" ? `${player.rank}.` : player.rank}
          </div>
          <div className="rank-cell__name">
            {player.podium_member && <WapIcon color={variables.colorWap} />}

            <Link to={`/${player.handle.toLowerCase()}`}>
              {player.screen_name}
            </Link>
          </div>
        </div>
      </td>
      <td className="figure">{intComma(player.roi)}%</td>
      <td className="figure">{intComma(player.resolved_count)}</td>

      <td className="figure">{intComma(player.resolved_waged)}</td>
      <td className="figure">
        {intComma(player.gross - player.resolved_waged)}
      </td>
    </tr>
  );
}

Contest.propTypes = {
  players: PropTypes.array
};

export default Contest;
