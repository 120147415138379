import React from "react";
import { Link } from "react-router-dom";
import { intComma } from "humanize-plus";

function TransactionItem(props) {
  let transaction = props.transaction;
  let date = new Date(transaction.date);
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  let formattedDate = `${months[date.getMonth()]} ${date.getDate()}`;

  return (
    <tr className={transaction.amount > 0 ? "transaction__row--profit" : ''}>
      <td>
        {transaction.memo}
        {transaction.proposition && (
          <Link to={`/prop/${transaction.proposition.slug}`}>
            {transaction.proposition.name}
          </Link>
        )}
      </td>
      <td>{formattedDate}</td>
      
      <td className="figure">{transaction.amount > 0 ? '+' : ''}{intComma(transaction.amount)}</td>

      <td className="figure">{intComma(transaction.newbalance)}</td>
    </tr>
  );
}

export default TransactionItem;
