// Third-party libraries
import React from "react";
import { Link } from "react-router-dom";

// Project dependencies

function HomeWelcome(props) {
  if (navigator.userAgent === "ReactSnap") {
    return null;
  }

  return props.loggedIn ? null : (
    <section
      onClick={props.hideMobileSidebar}
      className={`home-welcome alert alert alert--full-width 
      ${props.showMobileSidebar ? "alert--full-width-show-mobile-sidebar" : ""}
      ${
        props.location.pathname.indexOf("prop") > 0
          ? "home-welcome--prop-page"
          : ""
      }`}
    >
      <h1 className="home-welcome__h1">
        Welcome to Rouleur Derby
        <span className="home-welcome__h1--secondary">
          , the exciting game that lets you make fantasy bets on professional
          cycling.
        </span>
      </h1>

      <p>
        Rouleur Derby is free to play, and new players can join at any time.
      </p>
      <p>
        To get in on the action, <Link to="/rd/new">create an account</Link>.
      </p>
      <p className="home-welcome__login">
        <Link to="/rd/login" className="home-welcome__login-link">
          Log in
        </Link>
      </p>
    </section>
  );
}

export default HomeWelcome;
